import React from 'react'
import moment from 'moment'
import { isWeb, SizableText } from '@mythical/ui'
import { Wrapped } from 'app/blocks/homepage/wrapped'
import Section from 'app/components/structure/Section'
import { ButtonLink } from 'app/components/primitive/Button'
import useAnalytics from 'app/lib/analytics'
import { isAfter, isBefore } from 'date-fns'

export const WrappedBlock: React.FC<{ block: Wrapped }> = ({ block }) => {
  const { wrappedTitle, wrappedDescription, ctaLabel, scheduling } = block
  const analytics = useAnalytics()

  if (isWeb) {
    return null
  }

  const currentDate = new Date()
  const startDate = new Date(scheduling.startDate)
  const endDate = new Date(scheduling.endDate)

  if (isBefore(currentDate, startDate) || isAfter(currentDate, endDate)) {
    return null
  }

  return (
    <Section title={wrappedTitle} centered padded>
      {wrappedDescription && (
        <SizableText
          ff="$heading"
          size="$5"
          fow="500"
          color="$primary1"
          ta="center"
        >
          {wrappedDescription}
        </SizableText>
      )}
      <ButtonLink
        href="/wrapped"
        onPressCustom={() => {
          analytics.logEvent('wrapped_open', {
            campaign_id: `wrapped_${moment().year()}`,
          })
        }}
      >
        {ctaLabel || 'View Flashback'}
      </ButtonLink>
    </Section>
  )
}
