import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import * as z from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { Form, SizableText, XStack, YStack } from '@mythical/ui'
import { Button } from 'app/components/primitive/Button'
import { InputRhf, SelectRhf } from 'app/components/primitive/Form'
import { getData } from 'country-list'
import { UsaStates } from 'usa-states'

const countryOptions = getData().map(({ name, code }) => ({
  label: name,
  value: code,
}))

const usStates = new UsaStates().states.map((state) => ({
  label: state.name,
  value: state.abbreviation,
}))

const formSchema = z.object({
  name: z.string().min(1, 'Name is required'),
  address1: z.string().min(1, 'Address is required'),
  address2: z.string().optional(),
  city: z.string().min(1, 'City is required'),
  provinceCode: z.string().min(1, 'Province/State is required'),
  countryCode: z.string().min(1, 'Country is required'),
  zip: z.string().min(1, 'ZIP/Postal Code is required'),
  phone: z.string().optional(),
  stockItemId: z.string(),
})

export type RedemptionFormValues = z.infer<typeof formSchema>

interface RedemptionFormProps {
  onSubmit: (data: RedemptionFormValues) => void
  defaultValues: Partial<RedemptionFormValues>
  isSubmitting?: boolean
  hasSucceeded?: boolean
  redeemButtonText?: string
  stockItems?: {
    id: string
    name: string
  }[]
  stockItemId?: string
}

export const RedemptionForm = (props: RedemptionFormProps) => {
  const { defaultValues } = props
  const [selectedCountry, setSelectedCountry] = useState(
    defaultValues.countryCode || ''
  )
  const { control, handleSubmit, watch } = useForm<RedemptionFormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      name: defaultValues.name || '',
      address1: defaultValues.address1 || '',
      address2: defaultValues.address2 || '',
      city: defaultValues.city || '',
      provinceCode: defaultValues.provinceCode || '',
      countryCode: defaultValues.countryCode || '',
      zip: defaultValues.zip || '',
      phone: defaultValues.phone || '',
      stockItemId: props.stockItemId || '',
    },
  })

  const watchCountry = watch('countryCode')

  useEffect(() => {
    setSelectedCountry(watchCountry)
  }, [watchCountry])

  return (
    <Form w="100%" flex={1} onSubmit={handleSubmit(props.onSubmit)}>
      <YStack gap="$3.5" flex={1} $gtSm={{ fd: 'column', gap: '$7' }}>
        {props.stockItems && props.stockItems.length > 0 && (
          <YStack gap="$3.5">
            <SelectRhf
              theme="outlineSecondary9"
              id="stockItemId"
              name="stockItemId"
              label="Select a collectable"
              fullWidth
              control={control}
              required
              options={props.stockItems.map((stockItem) => ({
                label: stockItem.name,
                value: stockItem.id,
              }))}
            />
          </YStack>
        )}
        <YStack gap="$3.5" flex={1}>
          <InputRhf
            theme="outlineSecondary9"
            id="name"
            name="name"
            label="Full Name"
            placeholder="Enter your full name"
            fullWidth
            control={control}
            required
          />
          <InputRhf
            theme="outlineSecondary9"
            id="phone"
            name="phone"
            label="Phone Number"
            placeholder="Enter your phone number"
            fullWidth
            control={control}
          />
        </YStack>
        <YStack gap="$3.5" flex={1}>
          <InputRhf
            theme="outlineSecondary9"
            id="address1"
            name="address1"
            label="Address Line 1"
            placeholder="Enter your street address"
            fullWidth
            control={control}
            required
          />
          <InputRhf
            theme="outlineSecondary9"
            id="address2"
            name="address2"
            label="Address Line 2"
            placeholder="Apartment, suite, etc. (optional)"
            fullWidth
            control={control}
          />
          <XStack w="100%" flex={1}>
            <InputRhf
              theme="outlineSecondary9"
              id="city"
              name="city"
              label="City"
              placeholder="Enter your city"
              control={control}
              required
              containerProps={{
                flexBasis: '60%',
                pr: '$2',
              }}
            />
            <InputRhf
              theme="outlineSecondary9"
              id="zip"
              name="zip"
              label="ZIP / Postcode"
              placeholder="Enter ZIP"
              control={control}
              required
              containerProps={{
                flexBasis: '40%',
              }}
            />
          </XStack>
          <SelectRhf
            theme="outlineSecondary9"
            id="countryCode"
            name="countryCode"
            label="Country"
            placeholder="Select your country"
            fullWidth
            control={control}
            required
            options={countryOptions}
          />
          {selectedCountry === 'US' ? (
            <SelectRhf
              theme="outlineSecondary9"
              id="provinceCode"
              name="provinceCode"
              label="State"
              placeholder="Select your state"
              fullWidth
              control={control}
              required
              options={usStates}
            />
          ) : (
            <InputRhf
              theme="outlineSecondary9"
              id="provinceCode"
              name="provinceCode"
              label="Province / State"
              placeholder="Enter province/state"
              control={control}
              required
            />
          )}
        </YStack>

        <Form.Trigger asChild fd="row" ai="center">
          <Button size="$3" $gtSm={{ size: '$4' }} loading={props.isSubmitting}>
            {props.redeemButtonText || 'Redeem Collectable'}
          </Button>
        </Form.Trigger>
      </YStack>
    </Form>
  )
}
