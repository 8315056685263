import { Mutation } from 'app/types/mutation'
import { gql } from 'graphql-request'

export const redeemCollectibleMutation = () => gql`
  mutation RedeemCollectible(
    $blockId: String!
    $itemId: String!
    $name: String!
    $address1: String!
    $address2: String
    $city: String!
    $provinceCode: String!
    $countryCode: String!
    $zip: String!
    $phone: String
  ) {
    redeem(
      blockId: $blockId
      itemId: $itemId
      name: $name
      address1: $address1
      address2: $address2
      city: $city
      provinceCode: $provinceCode
      countryCode: $countryCode
      zip: $zip
      phone: $phone
    ) {
      success
      reason
      orderId
    }
  }
`

export const redeemCollectibleResult = (data: any) => {
  return data.redeem as Mutation
}